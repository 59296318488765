import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Media from "react-md/lib/Media/Media";
import MediaOverlay from "react-md/lib/Media/MediaOverlay";
import Divider from "react-md/lib/Dividers/Divider";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./DocSubmission.scss";

class DocSubmission extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Grid className="wrapper">
          <Cell size={3}></Cell>
          <Cell size={6}>
            <h2 className="md-headline">Errors and omissions in FAA paperwork can seriously delay recordation of your interest in an aircraft.</h2>
            <p className="md-body-1">
              The procedures followed by FAA for processing documents have evolved over many decades. Incorporated into these “basic” procedures are countless memos, previously published manuals and guides, legal opinions, letters, notes, meeting minutes, and the like. If that’s not enough to convince you “going it alone” isn’t in your best interest, consider this. The present-day procedures manual is 198 pages plus examples, appendices, and other supplemental materials FAA examiners can reference when processing your documents.
              <br /><br />
              Put our document submission service to work for you. We’ll review your paperwork to make sure everything’s in order, hand-deliver your documents to FAA, advance required fees on your behalf, and keep an eye on things to ensure your paperwork is processed without hitches or unnecessary delays.​
            </p>
          </Cell>
          <Cell size={3}></Cell>
        </Grid>
        <Card className="md-grid md-cell--8">
          <div className="centered">
            <CardText>
              <p className="md-body-1 centered">
                FAA Document Submission is a key component of our Escrow Service – a very important part of any aircraft transaction.
              </p>
            </CardText>
          </div>
        </Card>
      </div>
    );
  }
}

export default DocSubmission;
